.chatbot-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
    z-index: -1; /* Ensure modal is on bottom when close of other elements */
  }
  
  .chatbot-modal.active {
    opacity: 1;
    z-index: 999; /* Ensure modal is on top of other elements */
    pointer-events: auto; /* Allow interaction with modal content */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    max-width: 800px; /* Set a maximum width for responsiveness */
    width: 100%; /* Ensure content fills available space within modal */
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 18px;
  }
  
  .close-button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-weight: bold;
  }
  
  .modal-footer {
    text-align: right;
    margin-top: 15px;
  }
  .messages {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  .message {
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
  }
  
  .user {
    background-color: #e0f7fa;
    align-self: flex-end;
    text-align: right;
  }
  
  .bot {
    background-color: #f1f1f1;
    align-self: flex-start;
    text-align: left;
  }
  
  .input-container {
    display: flex;
    margin-top: 10px;
  }
  
  .chat-input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .send-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  
  .chat-input:focus {
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    outline: none;
  }

  .slide-in-left {
    animation: slideInLeft 1s ease-out;
  }
  
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .bounce-in {
    animation: bounceIn 1s ease-in-out;
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    60% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }